<template>
  <a-col class="h-100 overflow-hidden">
    <a-row class="content-title" type="flex">
      <h2 style="margin-bottom: 0">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="$router.push({ name: 'menu-create' })"
      >
        <a-icon type="plus" />
        {{ $t("Add") }}
      </a-button>
    </a-row>

    <spinner v-if="loading" />

    <a-row v-else class="list-container menu-list__container thin-scroll">
      <Tree :value="sortChildMenuList(list)">
        <a-row
          v-if="!node.isDragPlaceHolder"
          slot-scope="{ node, tree, path }"
          type="flex"
          align="middle"
        >
          <a-icon
            v-if="node.children && node.children.length"
            style="padding-right: 10px"
            :type="node.$folded ? 'plus' : 'minus'"
            @click="tree.toggleFold(node, path)"
          />

          <a-input-number
            v-model="node.position"
            style="width: 60px; margin-right: 10px"
            :min="1"
            @pressEnter="updatePosition(node, $event)"
          />

          <router-link
            :to="{
              name: 'menu-detail',
              params: {
                id: node.id
              }
            }"
            class="td-post-item"
            tag="span"
          >
            <span v-if="node.is_main" class="mr-1">
              <a-icon type="pushpin" />
            </span>
            <span>
              <b>(ID: {{ node.id }})</b> {{ node.title || "Нет название" }}
            </span>
          </router-link>

          <a-row class="btns" type="flex" align="middle">
            <div class="d-flex__center-items__col-reverse">
              <a-switch
                v-model="node.is_main"
                style="margin-top: 5px"
                @change="checkMain(node, $event)"
              />
              <a-tag
                style="min-width: 110px; text-align: center"
                :color="node.is_main ? 'geekblue' : 'volcano'"
              >
                {{ node.is_main ? "На главной" : "Не на главной" }}
              </a-tag>
            </div>

            <div class="d-flex__center-items__col-reverse">
              <a-switch
                v-model="node.is_active"
                style="margin-top: 5px"
                @change="checkActive(node, $event)"
              />
              <a-tag
                style="min-width: 110px; text-align: center"
                :color="node.is_active ? 'geekblue' : 'volcano'"
              >
                {{ node.is_active ? $t("Published") : $t("NotPublished") }}
              </a-tag>
            </div>

            <a-button
              type="primary"
              class="edit-btn"
              @click="
                $router.push({ name: 'menu-detail', params: { id: node.id } })
              "
            >
              <!--{{ $t("Edit") }}-->
              <a-icon type="edit" class="action-btns" />
            </a-button>

            <a-popconfirm
              title="Вы действительно хотите удалить?"
              ok-text="Да"
              cancel-text="Нет"
              @confirm="removeItem(node)"
            >
              <a-button style="margin-right: 10px" type="danger">
                <a-icon class="action-btns" type="delete" />
                <!--{{ $t("Delete") }}-->
              </a-button>
            </a-popconfirm>

            <a-row class="btns" type="flex-row" align="middle">
              <div>
                <b>{{ $t("TableCreatedDate") }}: </b>
                <span>{{ moment(node.created_at).format("LLL") }}</span>
              </div>

              <hr />

              <div>
                <b>{{ $t("TableLastUpdatedDate") }}: </b>
                <span>{{ moment(node.updated_at).format("LLL") }}</span>
              </div>
            </a-row>
          </a-row>
        </a-row>
      </Tree>
    </a-row>
  </a-col>
</template>

<script>
import "he-tree-vue/dist/he-tree-vue.css"
import { Tree, Fold } from "he-tree-vue"

export default {
  components: {
    Tree: Tree.mixPlugins([Fold])
    // breadthFirstSearch
  },
  data() {
    return {
      loading: false,
      list: []
    }
  },
  computed: {},
  mounted() {
    this.fetchData()
  },
  methods: {
    updatePosition(node, event) {
      this.loading = true
      this.$api
        .patch(`/admin/menu/${node.id}/update/`, {
          position: event.target.value
        })
        .then(() => {
          this.loading = false
          this.$message.success("Позиция обновлена")
          this.fetchData()
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err.message)
        })
    },
    async checkActive(item, event) {
      await this.$api.patch(`/admin/menu/${item.id}/update/`, {
        is_active: event
      })
    },
    async checkMain(item, event) {
      await this.$api.patch(`/admin/menu/${item.id}/update/`, {
        is_main: event
      })
    },
    async fetchData() {
      this.loading = true
      try {
        const data = await this.$store.dispatch("menu/fetch", {
          parent: true
        })
        this.$set(this, "list", data)

        this.loading = false
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async removeItem(arg) {
      console.log(arg)
      try {
        const res = await this.$store.dispatch("menu/removeItemAction", arg.id)
        if (res) {
          this.$message.success("Меню успешно удален")
          await this.fetchData()
        } else {
          this.$message.error("Ошибка при удалении")
          throw "Не удалось удалить"
        }
        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    sortChildMenuList(list) {
      return (
        list &&
        list
          .map((item) => ({
            ...item,
            $folded: true,
            children:
              (item.children && this.sortChildMenuList(item.children)) || []
          }))
          .sort((a, b) => a.position - b.position)
      )
    }
  }
}
</script>

<style lang="scss">
.tree-node-inner {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
}

// .draggable-placeholder {
// }

.draggable-placeholder-inner {
  border: 1px dashed #0088f8;
  box-sizing: border-box;
  background: rgba(0, 136, 249, 0.09);
  color: #0088f9;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
}

.tree3 .tree-node-inner {
  border: none;
  padding: 0px;
}

.tree3 .tree-node-inner-back:hover {
  background: #ddd;
}

.tree4 .tree-node-inner {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  background: #ccc;
}

.tree4 .draggable-placeholder-inner {
  background-color: orangered;
}
.he-tree .tree-node {
  padding: 8px 15px;
}
</style>
